import React, { memo, useEffect, useRef, useState } from "react";
import FormOne from "../components/FormOne";
import axios from "axios";
import Toastify from "toastify-js";
import Sidebar from "./SideBar";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RenderStep1 = () => {
  const [selectionType, setSelectionType] = useState("single");
  const [selectedItems, setSelectedItems] = useState([]);
  const [multipleFireCompartmentsType, setmultipleFireCompartmentsType] = useState("single");

  const navigate = useNavigate();
  const clientId = sessionStorage.getItem("clientId");

  const [formData, setFormData] = useState({
    client: "",
    projectName: "",
    address: "",
    architect: "",
    projectDescription: "",
    jobNumber: "",
    buildingContain: [],
    classification: [],
    selectedConstruction: "",
    riseInStoreys: "",
    effectiveHeight: "",
    largestFireCompartmentSize: "",
    buildingArea: "",
  });

  useEffect(() => {
    // Fetch Step 1 data when editing an existing document
    getStepOneData();
  }, []);

  const getStepOneData = async () => {
    const documentId = sessionStorage.getItem("documentId");

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/stepForm/step-1`, {
        params: { clientId, documentId }
      });

      if (response.status === 200) {
        const stepOneData = response.data.stepOneData;
        const prefilledData = {};
        stepOneData.forEach(item => {
          prefilledData[item.fieldname] = item.values;
        });

        setFormData(prevData => ({
          ...prevData,
          ...prefilledData
        }));
      }
    } catch (error) {
      console.error("Error fetching Step 1 data:", error);
    }
  };

  const handleStepOneCompletion = async () => {
    // Save or retrieve documentId from the API
    const documentId = sessionStorage.getItem("documentId");

    if (documentId) {
      console.log("Document ID exists:", documentId);
      return documentId;
    } else {
      console.error("Failed to retrieve documentId.");
      return false;
    }
  };

  const handleNextStep = async () => {

    // const documentId = sessionStorage.getItem("documentId");
    // if (!documentId) {
    //   console.error("Document ID is missing. Cannot proceed to Step 2.");
    //   return;
    // }

    const isValid = validateFormData(formData);
    if (!isValid) {
      alert("Please fill in the mandatory fields.");
      return;
    }

    const STEP1_URL = `${process.env.REACT_APP_BACKEND_URL}/stepForm/step-1`;

    const payload = {
      clientId,
      // documentId,
      data: prepareFormDataPayload(formData, selectedItems)
    };

    try {
      const response = await axios.post(STEP1_URL, payload);
      if (response.status === 201) {
        console.log('Response: ',response.data);
        sessionStorage.setItem("documentId", response.data.updatedData[0].documentId);
        
        // Call handleStepOneCompletion after saving the documentId
        let docId = await handleStepOneCompletion();
        if (docId) {
          navigate("/step2");
        } else {
          console.error("Document Id is missing:");
        }
      }
    } catch (error) {
      console.error("Error submitting Step 1 data:", error);
    }
  };

  const validateFormData = (formData) => {
    return (
      formData.selectedConstruction &&
      formData.classification.length > 0 &&
      formData.riseInStoreys &&
      formData.effectiveHeight &&
      formData.buildingArea &&
      formData.client &&
      formData.projectName &&
      formData.address &&
      formData.architect &&
      formData.projectDescription &&
      formData.jobNumber
    );
  };

  const prepareFormDataPayload = (formData, selectedItems) => {
    return [
      {
        fieldname: "Client",
        values: formData.client,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Project Name",
        values: formData.projectName,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Address",
        values: formData.address,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Architect",
        values: formData.architect,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Project Description",
        values: formData.projectDescription,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Job Number",
        values: formData.jobNumber,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Building Classification",
        values: "",
        multiple: true,
        multivalues: prepareClassificationData(selectedItems)
      },
      {
        fieldname: "Type of Construction",
        values: formData.selectedConstruction,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Rise in Storeys",
        values: formData.riseInStoreys,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Effective Height",
        values: formData.effectiveHeight,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Largest Fire Compartment Size",
        values: formData.largestFireCompartmentSize,
        multiple: false,
        multivalues: []
      },
      {
        fieldname: "Building Area",
        values: formData.buildingArea,
        multiple: false,
        multivalues: []
      }
    ];
  };

  const prepareClassificationData = (selectedItems) => {
    const classifications = [
      "2", "3", "4", "5", "6", "7a", "7b", "8", "9a", "9b", "9c"
    ];

    return classifications.map((classification) => ({
      [classification]: selectedItems.includes(classification)
    }));
  };

  return (
    <div className="content">
      <div className="container multipleForm">
        <div className="row justify-content-center">
          <div className="col">
            <div className="bg-white pb-4 shadow-sm m-lg-5 my-4 border overflow-hidden rounded-3">
              <div>
                <h3 className="bg-primary p-2">Step 1: Building Information</h3>
                <FormOne
                  formData={formData}
                  setFormData={setFormData}
                  selectionType={selectionType}
                  setSelectionType={setSelectionType}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  multipleFireCompartmentsType={multipleFireCompartmentsType}
                  setmultipleFireCompartmentsType={setmultipleFireCompartmentsType}
                />
              </div>
              <div className="mt-3 px-3">
                <button
                  className="btn bg-primary border ms-2"
                  onClick={handleNextStep}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(RenderStep1);
