import React, { useState } from 'react';  // Import useState from React
import { Link, useNavigate } from 'react-router-dom';  // Import Link and useNavigate from react-router-dom

const TopNavbar = ({ showTab = true }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();  // React Router's navigation hook

  // Sample handleLogout function, if it wasn't defined before
  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };

  const [tab, setTab] = useState(showTab ? [
    { label: "Form", href: 'step1' },
    { label: "Document", href: 'Documents' },
  ] : []);

  const tabHandle = (index) => {
    setActiveIndex(index);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-sticky MenuTopbar">
      <div className="container">
        {/* Back Button */}
        <button onClick={() => navigate(-1)} className="back-button">
          Back
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            {tab.map((item, index) => (
              <li
                key={index}
                onClick={() => tabHandle(index)}
                className={`${activeIndex === index ? 'active' : ''} nav-item`}
              >
                <Link className="nav-link" to={`/${item.href}`}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <button className="btn btn-danger @bg-primary" onClick={handleLogout}>Logout</button>
      </div>
    </nav>
  );
};

export default TopNavbar;
