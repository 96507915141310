import React, { useState } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import TopNavbar from "../../components/Navbar";
import LoginForm from "../../form/LoginForm";
import DocumentList from "../../components/DocumentList";
import MultiStepForm from "../../form/MultiStepForm";
import ResetPasswordForm from "../../components/ResetPasswordForm";
import RenderStep1 from "../../form/step1";
import RenderStep2 from "../../form/step2";
import RenderStep3 from "../../form/step3";
import Forgotpassword from "../../components/Forgotpassword";
import Option from "../../form/option";

function User() {
  const currentUser = sessionStorage.getItem("clientId");
  const [isLoggedIn, setIsLoggedIn] = useState(currentUser);

  const ProtectedRoute = ({ children }) => {
    const currentUser = sessionStorage.getItem("clientId"); // Always check for session user
    
    if (!currentUser) {
      // If the user isn't logged in, navigate to login, but don't force a redirect that breaks back navigation.
      return <Navigate to="/login" replace={true} />;  // Using 'replace' prevents adding this to history, helping the back button
    }
  
    return children;
  };
  

  const Layout = () => {
    return (
      <>
        <TopNavbar showTab={window.location.pathname !== "/"}/>
        <div>
          <Outlet />
        </div>
      </>
    );
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/documents"
          element={
            <div className="container">
              {" "}
              <DocumentList userId={currentUser} />
            </div>
          }
        />
                <Route path="/" element={<Option />} />
        <Route path="/step1" element={<RenderStep1 />} />
        <Route path="/step2" element={<RenderStep2 />} />
        <Route path="/step3" element={<RenderStep3 />} />
      </Route>
      <Route path="/forgotpassword" element={<Forgotpassword />} />
      <Route path="/reset-password" element={<ResetPasswordForm />} />
      <Route
        path="/login"
        element={<LoginForm />}
      />
    </Routes>
  );
}

export default User;
